<template>
  <div class="table-wrapper">
    <table border="1">
      <thead>
        <tr>
          <th>Collection</th>
          <th>Launch Date</th>
          <th>Designer</th>
          <th>Merchandiser</th>
          <th>Factory</th>
          <th>Timeline</th>
          <th
            v-for="step in steps"
            :key="step">
            {{ step }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(collection, collectionName, collectionIndex) in data">
          <template
            v-for="(factory, factoryName, factoryIndex) in collection.factorys"
          >
            <template
              v-for="(timeline, timelineName, timelineIndex) in factory.timelines"
            >
              <tr :key="collectionIndex + '-' + factoryIndex + '-' + timelineIndex">
                <td
                  v-if="factoryIndex === 0 && timelineIndex === 0"
                  :rowspan="getRowSpan(collection)">
                  <div :class="[collection.status]">
                    <b>{{ collectionName }}</b>
                  </div>
                </td>
                <td
                  v-if="factoryIndex === 0 && timelineIndex === 0"
                  :rowspan="getRowSpan(collection)">
                  <div :class="[collection.status]">
                    <b>{{ formatDate(new Date(collections[collectionName]['Launch Date'])) }}</b>
                  </div>
                </td>
                <td
                  v-if="factoryIndex === 0 && timelineIndex === 0"
                  :rowspan="getRowSpan(collection)">
                  <div :class="[collection.status]">
                    <!-- <img :src="collections[collectionName]['Designer'][0].avatar_url">
                    <br><br> -->
                    <b>{{ collections[collectionName]['Designer'][0].name }}</b>
                  </div>
                </td>
                <td
                  v-if="factoryIndex === 0 && timelineIndex === 0"
                  :rowspan="getRowSpan(collection)">
                  <div :class="[collection.status]">
                    <!-- <img :src="collections[collectionName]['Merchandiser'][0].avatar_url">
                    <br><br> -->
                    <b>{{ collections[collectionName]['Merchandiser'][0].name }}</b>
                  </div>
                </td>
                <td
                  v-if="timelineIndex === 0"
                  :rowspan="getFactoryRowSpan(factory)">
                  <div :class="[factory.status]">
                    <b> {{ factoryName }}</b>
                  </div>
                </td>
                <td>
                  <div :class="[timeline.status]">
                    <b>{{ timelineName }}</b>
                  </div>
                </td>
                <td
                  v-for="step in steps"
                  :key="step"
                >
                  <div :class="[timeline.steps[step].status]">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >{{ formatDate(timeline.steps[step].dueDate) }}
                          <br>
                          {{ timeline.steps[step].doneRate }}%
                          ({{ timeline.steps[step].doneRatio }})</span>
                      </template>
                      <span><b>{{ collectionName }}</b></span>
                      <br>
                      <span><b>{{ factoryName }} ({{ timelineName }})</b></span>
                      <br>
                      <span>{{ step }}</span>
                    </v-tooltip>
                    <!-- {{ formatDate(timeline.steps[step].dueDate) }}
                    <br>
                    {{ timeline.steps[step].doneRate }}%
                    ({{ timeline.steps[step].doneRatio }}) -->
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

  <script>
  export default {
    data () {
      return {
        steps: [],
        collections: {},
        data: {}
      }
    },
    async mounted () {
      try {
        const stepsResponse = await fetch(`https://storage.googleapis.com/gw_production_plan/steps.json?t=${Date.now()}`)
        const stepsJsonData = await stepsResponse.json()
        this.steps = stepsJsonData

        // ====================

        const collectionResponse = await fetch(`https://storage.googleapis.com/gw_production_plan/collection.json?t=${Date.now()}`)
        const collectionJsonData = await collectionResponse.json()
        this.collections = collectionJsonData

        // ====================

        const response = await fetch(`https://storage.googleapis.com/gw_production_plan/fusion.json?t=${Date.now()}`)
        const jsonData = await response.json()
        for (const [, collection] of Object.entries(jsonData)) {
          for (const [, factory] of Object.entries(collection.factorys)) {
            for (const [, timeline] of Object.entries(factory.timelines)) {
              this.steps.forEach((step) => {
                // done
                // upcoming soon late
                timeline.steps[step].dueDate = new Date(timeline.steps[step].dueDate)
                const done = this.sum(timeline.steps[step].record)
                const total = timeline.steps[step].record.length
                timeline.steps[step].doneRate = ((done / total) * 100).toFixed(0)
                timeline.steps[step].doneRatio = `${done}/${total}`
                if (timeline.steps[step].doneRate === '100') {
                  timeline.steps[step].status = 'done'
                } else {
                  const dueDate = new Date(timeline.steps[step].dueDate)
                  const diff = Math.floor((dueDate - Date.now()) / 86400000)
                  if (diff > 5) {
                    timeline.steps[step].status = 'upcoming'
                  } else if (diff >= -1) {
                    timeline.steps[step].status = 'soon'
                  } else {
                    timeline.steps[step].status = 'late'
                  }
                }
              })
              timeline.status = this.getHighestStatus(timeline.steps)
            }
            factory.status = this.getHighestStatus(factory.timelines)
          }
          collection.status = this.getHighestStatus(collection.factorys)
        }
        //
        //
        this.data = jsonData
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    methods: {
      getHighestStatus (input) {
        const statusPriority = ['done', 'upcoming', 'soon', 'late']
        let highestStatus = 'done'

        // ใช้ Object.values() เพื่อดึงค่าทั้งหมดของ object แล้วใช้ forEach
        Object.values(input).forEach(({ status }) => {
          if (statusPriority.indexOf(status) > statusPriority.indexOf(highestStatus)) {
            highestStatus = status
          }
        })

        return highestStatus
      },
      sum (myNums) {
        return myNums.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      },
      formatDate (date) {
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
      },
      getRowSpan (collection) {
        // Calculate rowspan for the Collection column based on the number of timelines in all factories within a collection
        return Object.values(collection.factorys)
          .reduce((sum, factory) => sum + Object.keys(factory.timelines).length, 0)
      },
      getFactoryRowSpan (factory) {
        // Calculate rowspan for the Factory column based on the number of timelines within the factory
        return Object.keys(factory.timelines).length
      }
    }
  }
  </script>

  <style scoped>
  .table-wrapper {
    overflow-x: auto; /* เปิดการ scroll แนวนอน */
    width: 100%; /* ให้ div มีขนาดเต็มหน้าจอ */
  }
  table, th, td {
    border: 1px solid rgb(235, 235, 243);
    border-collapse: collapse;
    background-color: #fff;
  }
  tr {
    height: 1px;
  }
  th, td {
    padding: 8px;
    text-align: center;
    height: inherit;
    text-wrap: nowrap;
  }
  /* thead { position: sticky; top: 0;} */
  td {
    font-size: 13px;
    color: #3c3e40;
  }
  .done {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(207, 243, 228);
    background-color: rgb(233 247 241);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  .soon {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(244, 230, 212);
    background-color:rgb(253 243 230);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  .late {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(237 219 216);
    background-color: rgb(249 236 232);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  .upcoming {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 2px solid rgb(214, 226, 251);
    background-color: rgb(230 239 254);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  </style>
